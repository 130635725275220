<template>
  <Main
    :triggerPushNotification="filterRead === 0 && page == 1 && formDataToSend.allTypes.all == true"
  >
    <div class="notifications">
      <div class="notifications__container">
        <h1>Уведомления</h1>
        <div class="notifications__header">
          <div class="notifications__toggle">
            <div
              :class="['notifications__toggle-item', filterRead === 0 ? 'active' : '']"
              @click="handleFilterRead(0)"
            >
              Непрочитанные
              <span v-if="notificationAll != 0" class="notifications__no-read"></span>
            </div>
            <div
              :class="['notifications__toggle-item', filterRead === 1 ? 'active' : '']"
              @click="handleFilterRead(1)"
            >
              Прочитанные
            </div>
          </div>
        </div>
        <div class="notifications__tools">
          <div v-if="triggerTypes" class="notifications__sort">
            <div class="notifications__checkbox">
              <InputCheckboxMain
                @click.native="handleTypeAll"
                :label="'Все'"
                :id="'all'"
                :set-value="formDataToSend.allTypes"
              />
            </div>
            <div
              class="notifications__checkbox"
              v-for="type in notificationsTypes"
              :key="type.name"
            >
              <InputCheckboxMain
                @click.native="handleType"
                :label="type.title"
                :id="type.name + ''"
                :set-value="formDataToSend.notificationsTypes"
              />
            </div>
          </div>
          <div v-if="filterRead != 1" class="notifications__button">
            <ButtonMain @click.native="handleNotificationReadAll" :buttonGrey="true"
              >Отметить все как прочитанные</ButtonMain
            >
          </div>
        </div>
        <div v-if="notificationsList.length > 0" class="notifications__items-container">
          <div
            v-for="(notification, index) in notificationsList"
            :key="notification.id"
            class="notifications__item"
          >
            <div
              :class="[
                'notifications__item-header',
                notification.viewedAt == null ? 'no-read' : '',
              ]"
            >
              <div class="notifications__item-info">
                <div class="notifications__item-time">
                  {{ notification.createdAt.split(" ")[1] }}
                </div>
                <div
                  v-if="notification.category != 'order'"
                  class="notifications__item-description"
                >
                  {{ notification.message }}
                </div>
                <div v-else class="notifications__item-description">
                  {{ notification.message }} | Статус: {{ notification.order.status.title }}
                </div>
              </div>
              <div class="notifications__item-buttons">
                <div class="notifications__item-read">
                  <div
                    v-if="notification.readAt == null"
                    @click="handleReadNotification(notification.id)"
                    class="notifications__item-read no"
                  >
                    Отметить прочитанным
                  </div>
                  <div v-else class="notifications__item-read yes">Прочитано</div>
                </div>
                <div
                  v-if="notification.category == 'order'"
                  @click="toggleItem(index)"
                  :class="['notifications__item-more', isActive === index ? 'active' : '']"
                >
                  <SelectIconSmall />
                </div>
              </div>
            </div>
            <div v-if="isActive === index" class="notifications__item-detail">
              <div class="notifications__item-detail-container">
                <div
                  v-if="notification.category == 'order'"
                  class="notifications__item-detail-items"
                >
                  <div class="notifications__item-detail-items-group">
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Откуда:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.addressFromShort }}
                      </div>
                    </div>
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Куда:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.addressToShort }}
                      </div>
                    </div>
                  </div>
                  <div class="notifications__item-detail-items-group">
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Дата:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.date }}
                      </div>
                    </div>
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Время:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.time }}
                      </div>
                    </div>
                  </div>
                  <div class="notifications__item-detail-items-group">
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Тип авто:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.typeOfCar.name }}
                      </div>
                    </div>
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.typeOfCar.description }}
                      </div>
                    </div>
                  </div>
                  <div class="notifications__item-detail-items-group">
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Тариф:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.rate }}
                      </div>
                    </div>
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Цена:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.totalPrice }}
                      </div>
                    </div>
                  </div>
                  <div class="notifications__item-detail-items-group">
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Заказчик:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.client.name }}
                      </div>
                    </div>
                    <div class="notifications__item-detail-item">
                      <div class="notifications__item-detail-item--key">Телефон:</div>
                      <div class="notifications__item-detail-item--value">
                        {{ notification.order.client.phone }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="notification.category == 'driver'"
                  class="notifications__item-detail-items"
                >
                  Driver
                </div>
                <div
                  v-if="notification.category == 'client'"
                  class="notifications__item-detail-items"
                >
                  Client
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="notifications__item-container">
          <div class="notifications__no-item">Новых уведомлений нет</div>
        </div>
        <div
          v-if="notifications != null && notificationsList.length > 0"
          class="notifications__pagination"
        >
          <Pagination
            :maxPage="notifications.meta.lastPage"
            :page="page"
            :handlePageChange="handlePageChange"
          />
        </div>
      </div>
    </div>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";

import Main from "../templets/Main.vue";
import Pagination from "../components/molecules/Pagination.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import SelectIconSmall from "../assets/images/icons/select-icon-small.svg";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import InputCheckboxMain from "../components/atoms/inputs/InputCheckboxMain.vue";

export default {
  name: "NotificationsPage",
  data() {
    return {
      filterRead: 0,
      isActive: null,
      triggerTypes: false,
      formDataToSend: {
        notificationsTypes: {},
        allTypes: {
          all: true,
        },
      },
    };
  },
  components: {
    Main,
    Pagination,
    ButtonMain,
    FetchSpinnerModal,
    InputCheckboxMain,
    SelectIconSmall,
  },
  mounted() {
    this.seeAllNotification();
    this.getNotifications();
    this.getNotificationsTypes();
  },
  methods: {
    getNotifications() {
      let url = `?page=${this.page}&order=created_at&direction=desc`;
      if (this.filterRead === 0) {
        url += `&read=0`;
      } else if (this.filterRead === 1) {
        url += `&read=1`;
      }
      const typesArray = Object.keys(this.formDataToSend.notificationsTypes);
      const typesChoose = [];
      for (let i = 0; typesArray.length > i; i++) {
        if (this.formDataToSend.notificationsTypes[typesArray[i]] == true) {
          typesChoose.push(typesArray[i]);
        }
      }
      if (typesChoose.length > 0) {
        url += `&types=`;
        for (let i = 0; typesChoose.length > i; i++) {
          url += `${typesChoose[i]},`;
        }
      }
      this.$store.dispatch("notification/getNotificationsList", url);
    },
    handleFilterRead(mod) {
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.NOTIFICATIONS_PAGE.replace(":page", 1),
        });
      }
      this.filterRead = mod;
      this.getNotifications();
      if (mod == 0) {
        this.seeAllNotification();
      }
    },
    seeAllNotification() {
      setTimeout(() => {
        this.$store.commit("notification/setNotificationAll", 0);
        const typesArray = [];
        this.notificationsTypes.forEach((type) => {
          return typesArray.push(type.name);
        });
        this.$store.dispatch("notification/notificationViewed", {
          types: typesArray,
        });
      }, 3000);
    },
    toggleItem(index) {
      this.isActive = this.isActive === index ? null : index;
    },
    handlePageChange(page) {
      this.$router.push({
        path: ROUTE.NOTIFICATIONS_PAGE.replace(":page", page),
      });
      this.getNotifications();
    },
    handleReadNotification(id) {
      const data = {
        ids: [id],
      };
      this.$store.dispatch("notification/notificationRead", data).finally(() => {
        this.getNotifications();
      });
    },
    handleNotificationReadAll() {
      this.$store.dispatch("notification/notificationReadAll").finally(() => {
        this.getNotifications();
      });
    },
    getNotificationsTypes() {
      this.$store.dispatch("notification/getNotificationsTypes");
      this.setTypesCheckbox();
    },
    setTypesCheckbox() {
      const setTypes = () => {
        if (this.notificationsTypes === undefined && !this.notificationsTypes.length > 0) {
          setTypes();
        } else {
          const types = this.notificationsTypes;
          for (let i = 0; types.length > i; i++) {
            this.$set(this.formDataToSend.notificationsTypes, types[i].name, false);
          }
          this.triggerTypes = true;
        }
      };
      setTimeout(() => {
        setTypes();
      }, 2000);
    },
    handleType() {
      this.formDataToSend.allTypes.all = false;
      this.getNotifications();
    },
    handleTypeAll() {
      setTimeout(() => {
        this.formDataToSend.allTypes.all = true;
      }, 0);
      const typesArray = Object.keys(this.formDataToSend.notificationsTypes);
      for (let i = 0; typesArray.length > i; i++) {
        this.formDataToSend.notificationsTypes[typesArray[i]] = false;
      }
      this.getNotifications();
    },
  },
  computed: {
    ...mapState("notification", [
      "notifications",
      "error",
      "loadingProcess",
      "notificationsList",
      "notificationAll",
      "notificationsTypes",
    ]),
    page() {
      return +this.$route.params.page;
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  width: 100%;

  &__container {
    padding: 25px 17px;
  }

  &__toggle {
    @include flex-row;

    justify-content: space-between;
    overflow: hidden;
    width: 400px;
    height: 48px;
    margin-top: 20px;
  }

  &__toggle-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100%;
    background: $mainDisabled;
    font-size: 14px;
    font-weight: 500;
    color: $mainColor;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    &.active {
      background: $mainColor;
      color: $g-color-white;
    }
  }

  &__no-read {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #eb5959;
    border-radius: 50px;
    top: 5px;
    right: 5px;
  }

  &__items-container {
    padding-top: 20px;
  }

  &__item {
    border: 1px solid #dbdbdb;
  }

  &__item-header {
    @include flex-row;

    padding: 20px;
    justify-content: space-between;
    background: #f1f3f4;

    &.no-read {
      background: #daf2d4;
    }
  }

  &__item-info {
    @include flex-row;

    align-items: center;
  }

  &__item-time {
    font-size: 14px;
    color: #7d7d7d;
  }

  &__item-description {
    padding-left: 18px;
    font-size: 14px;
    color: #2f2f34;
  }

  &__item-buttons {
    @include flex-row;

    align-items: center;
  }

  &__item-read {
    font-size: 14px;

    &.no {
      color: #5fbf68;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    &.yes {
      color: #2f2f34;
    }
  }

  &__item-more {
    padding-left: 10px;
    cursor: pointer;

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__item-detail-container {
    padding: 20px;
    border-top: 1px solid #dbdbdb;
  }

  &__item-detail-items {
    @include flex-row;

    justify-content: space-between;
  }

  &__item-detail-item {
    @include flex-row;

    padding: 5px 0;

    &--key {
      font-size: 14px;
      color: #7d7d7d;
      padding-right: 8px;
    }

    &--value {
      font-size: 14px;
      padding-right: 8px;
    }
  }

  &__no-item {
    padding-top: 30px;
    font-size: 28px;
    font-weight: bold;
  }

  &__tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  }

  &__button {
    width: 250px;
  }

  &__sort {
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__checkbox {
    padding-right: 19px;
    padding-bottom: 15px;

    /deep/label {
      margin-left: 12px;
    }
  }

  /deep/.checkbox-input__checkbox-container {
    margin-right: 0;
  }
}
</style>
